import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function EditForm() {
    const location = useLocation(); // Access passed state
    const navigate = useNavigate(); // For navigation after updating
    const { item } = location.state; // Get the item data from state

    const [formData, setFormData] = useState(item);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        axios.post('https://reactapp.sarwar.org.in/backend/update_data.php', formData)
            .then(response => {
                alert('Record updated successfully!');
                navigate('/data'); // Redirect to the main page after update
            })
            .catch(error => {
                console.log('Error updating record:', error);
            });
    };

    return (
        <div className="container mt-5">
            <h3>Edit Record</h3>
            <form onSubmit={handleUpdate}>
                <div className="form-group mb-3">
                    <label>Name: </label>
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Roll: </label>
                    <input 
                        type="text" 
                        name="roll" 
                        value={formData.roll} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Class: </label>
                    <input 
                        type="text" 
                        name="class" 
                        value={formData.class} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Date of Birth: </label>
                    <input 
                        type="date" 
                        name="dob" 
                        value={formData.dob} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Village: </label>
                    <input 
                        type="text" 
                        name="vill" 
                        value={formData.vill} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Phone: </label>
                    <input 
                        type="text" 
                        name="phone" 
                        value={formData.phone} 
                        onChange={handleChange} 
                        className="form-control" 
                        required 
                    />
                </div>
                <button type="submit" className="btn btn-success">Update</button>
            </form>
        </div>
    );
}

export default EditForm;
