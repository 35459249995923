import React, { useState } from 'react'; // Import useState from React
import axios from 'axios'; // Import axios for HTTP requests

function Formsubmit() {
    const [formData, setFormData] = useState({
        name: '',
        roll: '',
        class: '',
        dob: '',
        vill: '',
        phone: ''
    });

    const [message, setMessage] = useState(''); // State to display success/error messages

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent page reload on form submit
        // Send form data to PHP backend using Axios
        axios.post('https://reactapp.sarwar.org.in/backend/save_data.php', formData)
            .then(response => {
                console.log('Form submitted successfully: ', response);
                setMessage('Form submitted successfully!');
                setFormData({ // Optionally reset the form
                    name: '',
                    roll: '',
                    class: '',
                    dob: '',
                    vill: '',
                    phone: ''
                });
            })
            .catch(error => {
                console.log('Error submitting form: ', error);
                setMessage('Error submitting form, please try again.');
            });
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Form Submit Page</h1>
            {message && <div className={`alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}>{message}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Name: </label>
                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Roll: </label>
                    <input type="text" className="form-control" name="roll" value={formData.roll} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Class: </label>
                    <input type="text" className="form-control" name="class" value={formData.class} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Date of Birth: </label>
                    <input type="date" className="form-control" name="dob" value={formData.dob} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Village: </label>
                    <input type="text" className="form-control" name="vill" value={formData.vill} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Phone: </label>
                    <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
                </div>
                <button type="submit" className="btn btn-primary btn-block">Submit</button>
            </form>
        </div>
    );
}

export default Formsubmit;
