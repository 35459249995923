import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function Data() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Use navigate to change pages

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get('https://reactapp.sarwar.org.in/backend/fetch_data.php')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error fetching data: ", error);
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            axios.post('https://reactapp.sarwar.org.in/backend/delete_data.php', { id })
                .then(response => {
                    alert('Record deleted successfully!');
                    fetchData(); // Refresh the data after deletion
                })
                .catch(error => {
                    console.log('Error deleting record:', error);
                });
        }
    };

    const handleEdit = (item) => {
        navigate(`/editform/${item.id}`, { state: { item } }); // Navigate to edit page with state
    };

    if (loading) {
        return <div className="text-center mt-5">Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Fetched Data</h2>

            {/* Display total number of students */}
            <h4>Total Students: {data.length}</h4>

            <table className="table table-bordered table-hover table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th>#</th> {/* Add row number column */}
                        <th>Name</th>
                        <th>Roll</th>
                        <th>Class</th>
                        <th>Date of Birth</th>
                        <th>Village</th>
                        <th>Phone</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td> {/* Show row number */}
                            <td>{item.name}</td>
                            <td>{item.roll}</td>
                            <td>{item.class}</td>
                            <td>{item.dob}</td>
                            <td>{item.vill}</td>
                            <td>{item.phone}</td>
                            <td>
                                <button onClick={() => handleEdit(item)} className="btn btn-warning btn-sm mr-2 px-5">Edit</button>
                                <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm px-2">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Data;
