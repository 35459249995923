import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Router and Route components
import Formsubmit from './components/Formsubmit';
import Data from './components/Data';
import Navbar from './components/Navbar';
import EditForm from './components/EditForm';
import Home from './components/Home';

function App() {
    return (
        <Router>
            <Navbar />  {/* Navbar will be present on all pages */}
            <Routes>
            <Route path="/" element={<Home />} />
                {/* Route for the form */}
                <Route path="/formsubmit" element={<Formsubmit />} />

                {/* Route for displaying data */}
                <Route path="/data" element={<Data />} />

                <Route path='/editform/:id' element={<EditForm />} />

                {/* Default route (e.g., homepage) */}
                <Route path="/" element={<h2>Welcome to the Home Page</h2>} />
            </Routes>
        </Router>
    );
}

export default App;
